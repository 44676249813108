<template>
	<ConfirmDialog></ConfirmDialog>
	
	<div class="p-grid" style="height: 100%">
		<div class="p-col-12 p-md-12" style="height: 100%">
			<div class="card p-fluid" style="height: 100%">
                <CrmDataTable baseEntityName="serviceappointment" :options="CrmDataTable_options" :isHeaderVisible="true" @onRowSelect="onRowSelect" />
            </div>
        </div>
    </div>
</template>

<script>
import user from '../store/user';

export default {
	data() {
		return {
			CrmDataTable_options: {
				searchAttributes: ["bm_kod", "bm_servistalebiidname", "bm_uniteidname", "bm_firmaidname", "bm_urunidname", "subject"],
				/*
				filterParent: [
					{ 'name': 'bm_sehir', 'filterOperator': 'eq', 'value': '34' },
					{ 'name': 'telephone1', 'filterOperator': 'like', 'value': '(212)%' },
					{ 'name': 'createdon', 'filterOperator': 'on-or-after', 'value': '2022-01-01' },
				],
				*/
				customFormat: [
					//{ 'logicalName': 'salesorder', 'attributeName' : 'bm_geneltoplam', 'format' : 'n0'},
				],
            },
		}
	},
	created() {
		if (this.profileData) {
			const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Servis Raporları');
			if (yetkisiVarmi == false) {
				this.$router.replace({ name: 'accessdenied' });
			}
		}
	},
	methods: {
		onRowSelect(data) {
			//this.$router.push({ name: 'serviceappointment', params: { id: data.entityId } });

			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'serviceappointment', params: { id: data.entityId } });
			window.open(routeData.href, '_blank', features);
		}
	},
	computed: {
		profileData(){
			return this.$store.getters.getProfile;
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
